<template>
  <div class="mb-5 border rounded-3 pt-3">
    <p class="tw-border-start fw-bolder mb-3">發票資訊</p>
    <!-- 發票抬頭 -->
    <div class="form-floating mb-3">
      <input
        type="text"
        class="form-control"
        id="R_Title"
        placeholder="發票抬頭(20字)"
        v-model="propsToReceiptInfo.title"
        maxlength="20"
      />
      <label for="R_Title">發票抬頭(20字)</label>
    </div>
    <!-- 統一編號 -->
    <div class="form-floating mb-3">
      <input
        type="text"
        class="form-control"
        id="R_Number"
        placeholder="統一編號(8碼)"
        v-model="propsToReceiptInfo.number"
        maxlength="8"
      />
      <label for="R_Number">統一編號(8碼)</label>
    </div>
    <!-- 電話 -->
    <div class="form-floating mb-3">
      <input
        type="text"
        class="form-control"
        id="R_Phone"
        maxlength="15"
        placeholder="電話"
        v-model="propsToReceiptInfo.phone"
      />
      <label for="R_Phone">電話(15字)</label>
    </div>
    <button class="tw-btn tw-btn-success mb-2" @click="copyAddress">
      同收件地址
    </button>
    <!-- 發票地址 -->
    <div class="row">
      <!-- 選擇縣市 -->
      <div class="col-lg-3 mb-md-0 mb-3 p-0 form-floating" v-if="!city.loading">
        <select
          class="form-select"
          id="R_SelectCity"
          aria-label="選擇縣市"
          v-model="propsToReceiptInfo.selectCity"
          @click="clearSelectArea"
        >
          <option value="">不選擇</option>
          <option :value="data.City" v-for="data in city.data" :key="data.City">
            {{ data.City }}
          </option>
        </select>

        <label for="R_SelectCity">選擇縣市</label>
      </div>
      <AreaLoading class="col-lg-3 mb-md-0 mb-3" v-else></AreaLoading>
      <!-- 選擇區域 -->
      <div class="col-lg-3 mb-md-0 mb-3 p-0 form-floating">
        <select
          class="form-select"
          id="R_SelectArea"
          aria-label="選擇區域"
          v-model="propsToReceiptInfo.selectArea"
        >
          <option value="">不選擇</option>
          <option :value="data.Zip5" v-for="data in area.data" :key="data.Zip5">
            {{ data.Area }}
          </option>
        </select>
        <label for="R_SelectArea">選擇區域</label>
      </div>
      <!-- 地址 -->
      <div class="col-lg-6 mb-md-0 mb-3 p-0 form-floating">
        <input
          type="text"
          class="form-control"
          id="R_Address"
          maxlength="30"
          placeholder="街道名"
          v-model="propsToReceiptInfo.address"
        />
        <label for="R_Address">街道名(30字)</label>
      </div>
    </div>
  </div>
</template>

<script>
import { GetCityArea } from '../../methods/API/getCityArea'

export default {
  props: {
    propsToReceiptInfo: {
      type: Object,
      default: {
        title: '',
        number: '',
        phone: '',
        selectCity: '',
        selectArea: '',
        address: '',
      }
    },
    propsToCheckoutInfo: {
      type: Object,
      default: {
        selectShppingType: '',
        selectPaymentType: '',
        name: '',
        phone: '',
        selectCity: '',
        selectArea: '',
        address: '',
        shippingCost: 0,
        D4shippingCost: 0,
        note: '',
        money: 0,
        wallet: 0,
      }
    }
  },
  emits: ['fromReceiptInfoData'],
  mixins: [GetCityArea],
  data() {
    return {
      isClearArea: false,
    }
  },
  created() {
    this.getCity()
  },
  watch: {
    // 選縣市
    'propsToReceiptInfo.selectCity'(val) {
      if (this.isClearArea) this.propsToReceiptInfo.selectArea = ''
      this.isClearArea = false
      this.getArea(val)
    },
    // 電話格式
    'propsToReceiptInfo.phone'(val) {
      if ( !/^[0-9]*$/.test(val) ) {
        this.propsToReceiptInfo.phone = val.replace(/[^\d]/g, "");
      }
    },
    // 發票號碼
    'propsToReceiptInfo.number'(val) {
      if ( !/^[0-9]*$/.test(val) ) {
        this.propsToReceiptInfo.number = val.replace(/[^\d]/g, "");
      }
    },
    // 更新到父層
    propsToReceiptInfo: {
      hander(val) {
        this.$emit('fromReceiptInfoData', val)
      },
      deep: true
    }
  },
  methods: {
    // 清除 selectArea
    clearSelectArea() {
      this.isClearArea = true
    },
    // 同收件地址
    copyAddress() {
      this.propsToReceiptInfo.selectCity = this.propsToCheckoutInfo.selectCity
      this.propsToReceiptInfo.selectArea = this.propsToCheckoutInfo.selectArea
      this.propsToReceiptInfo.address = this.propsToCheckoutInfo.address
    },
  },
}
</script>