<template>
  <div class="mb-3 row">
    <!-- 出貨資訊 -->
    <div class="col-md-6 mb-md-0 mb-3 border rounded-3 p-0 pt-3">
      <p class="tw-border-start fw-bolder mb-3">
        出貨資訊
        <span class="text-danger" v-if="shippingTypeNote"
          >({{ shippingTypeNote }})</span
        >
      </p>
      <!-- 出貨方式 -->
      <div class="row mb-3">
        <!-- 出貨方式 -->
        <div class="col-6 p-0">
          <template v-if="!shippingTypes.loading">
            <div class="form-floating" v-if="shippingTypes.data.length > 0">
              <select
                class="form-select"
                id="SelectShippingType"
                aria-label="選擇出貨方式"
                v-model="propsToCheckoutInfo.selectShppingType"
              >
                <option value="">不選擇出貨方式</option>
                <template v-for="data in shippingTypes.data" :key="data.id">
                  <option
                    :value="`${data.name},${data.cost}`"
                    v-if="
                      data.customerEnable && currentPage === 'C_CheckOutOrder'
                    "
                  >
                    {{ data.name }}
                  </option>
                  <option
                    :value="`${data.name},${data.cost}`"
                    v-if="data.adminEnable && currentPage !== 'C_CheckOutOrder'"
                  >
                    {{ data.name }}
                  </option>
                </template>
              </select>
              <label for="SelectShippingType">選擇出貨方式</label>
            </div>
            <p class="text-danger fw-bolder" v-else>您尚未有任何出貨方式!</p>
          </template>
          <AreaLoading v-else></AreaLoading>
        </div>
        <!-- 運費 -->
        <div class="col-6 p-0 d-flex align-items-center">
          <div
            class="form-floating w-100"
            v-if="currentPage !== 'C_CheckOutOrder'"
          >
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="ShippingCost"
              placeholder="運費"
              min="0"
              v-model="propsToCheckoutInfo.shippingCost"
              @input="
                propsToCheckoutInfo.shippingCost = $methods.numberToFixed(
                  propsToCheckoutInfo.shippingCost
                )
              "
            />
            <label for="ShippingCost">運費</label>
          </div>
          <p class="text-center w-100" v-else>
            運費: {{ propsToCheckoutInfo.shippingCost }}元
          </p>
        </div>
      </div>
      <!-- 預設出貨資訊 -->
      <div class="mb-3">
        <div class="form-floating" v-if="!receivingInfos.loading">
          <select
            class="form-select"
            id="SelectD4ShippingInfo"
            aria-label="選擇預設出貨資訊"
            v-model="selectD4ReceivingInfo"
          >
            <option value="null" disabled>選擇預設出貨資訊</option>
            <option
              :value="data"
              v-for="data in receivingInfos.data"
              :key="data.id"
            >
              {{ data.recipient }}，{{ data.phoneNumber }}，{{
                data.City + data.Area + data.address
              }}
            </option>
          </select>
          <label for="SelectD4ShippingInfo">選擇預設出貨資訊</label>
        </div>
        <AreaLoading v-else></AreaLoading>
      </div>
      <div class="row">
        <!-- 收件人姓名 -->
        <div class="col-6 p-0">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="Name"
              maxlength="15"
              placeholder="收件人姓名"
              v-model="propsToCheckoutInfo.name"
            />
            <label for="Name">收件人姓名(15字)</label>
          </div>
        </div>
        <!-- 收件人電話 -->
        <div class="col-6 p-0">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="Phone"
              maxlength="15"
              placeholder="收件人電話"
              v-model="propsToCheckoutInfo.phone"
            />
            <label for="Phone">收件人電話(15字)</label>
          </div>
        </div>
      </div>
      <!-- 收件人地址 -->
      <div class="row mb-3">
        <!-- 選擇縣市 -->
        <div
          class="col-lg-3 mb-md-0 mb-3 p-0 form-floating"
          v-if="!city.loading"
        >
          <select
            class="form-select"
            id="SelectCity"
            aria-label="選擇縣市"
            v-model="propsToCheckoutInfo.selectCity"
            @click="clearSelectArea"
          >
            <option value="">不選擇</option>
            <option
              :value="data.City"
              v-for="data in city.data"
              :key="data.City"
            >
              {{ data.City }}
            </option>
          </select>

          <label for="SelectCity">選擇縣市</label>
        </div>
        <AreaLoading class="col-lg-3 mb-md-0 mb-3" v-else></AreaLoading>
        <!-- 選擇區域 -->
        <div class="col-lg-3 mb-md-0 mb-3 p-0 form-floating">
          <select
            class="form-select"
            id="SelectArea"
            aria-label="選擇區域"
            v-model="propsToCheckoutInfo.selectArea"
          >
            <option value="">不選擇</option>
            <option
              :value="data.Zip5"
              v-for="data in area.data"
              :key="data.Zip5"
            >
              {{ data.Area }}
            </option>
          </select>
          <label for="SelectArea">選擇區域</label>
        </div>
        <!-- 地址 -->
        <div class="col-lg-6 mb-md-0 mb-3 p-0 form-floating">
          <input
            type="text"
            class="form-control"
            id="Address"
            maxlength="30"
            placeholder="街道名"
            v-model="propsToCheckoutInfo.address"
          />
          <label for="Address">街道名(30字)</label>
        </div>
      </div>
      <!-- 門市資訊 -->
      <div
        class="row"
        v-if="
          currentPage === 'CheckoutOrder' ||
          currentPage === 'OldCheckoutOrder' ||
          currentPage === 'C_CheckOutOrder'
        "
      >
        <!-- 門市編號 -->
        <div class="col-6 p-0">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="ShopNumber"
              placeholder="門市編號"
              v-model="propsToCheckoutInfo.shopNumber"
            />
            <label for="ShopNumber">門市編號</label>
          </div>
        </div>
        <!-- 門市名稱 -->
        <div class="col-6 p-0">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="ShopName"
              placeholder="門市名稱"
              v-model="propsToCheckoutInfo.shopName"
            />
            <label for="ShopName">門市名稱</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 p-0">
      <!-- 付款方式 -->
      <div class="border rounded-3 mb-3 pt-3">
        <p class="tw-border-start fw-bolder mb-3">
          付款方式
          <span class="text-danger" v-if="paymentTypeNote"
            >({{ paymentTypeNote }})</span
          >
        </p>
        <div class="mb-3">
          <template v-if="!paymentTypes.loading">
            <div class="form-floating" v-if="paymentTypes.data.length > 0">
              <select
                class="form-select"
                id="SelectPaymentType"
                aria-label="選擇付款方式"
                v-model="propsToCheckoutInfo.selectPaymentType"
              >
                <option value="">不選擇付款方式</option>
                <template v-for="data in paymentTypes.data" :key="data.id">
                  <option
                    :value="data.name"
                    v-if="
                      data.customerEnable && currentPage === 'C_CheckOutOrder'
                    "
                  >
                    {{ data.name }}
                  </option>
                  <option
                    :value="data.name"
                    v-else-if="
                      data.adminEnable && currentPage !== 'C_CheckOutOrder'
                    "
                  >
                    {{ data.name }}
                  </option>
                </template>
              </select>
              <label for="SelectPaymentType">選擇付款方式</label>
            </div>
            <p class="text-danger fw-bolder" v-else>您尚未有任何付款方式!</p>
          </template>
          <AreaLoading v-else></AreaLoading>
        </div>
        <!-- 購物金折抵 -->
        <div class="form-floating p-0">
          <input
            onwheel="this.blur()"
            type="number"
            class="form-control"
            id="ShoppingGoldDiscount"
            placeholder="購物金折抵"
            min="0"
            :max="wallet"
            v-model="propsToCheckoutInfo.money"
            @input="
              propsToCheckoutInfo.money = $methods.numberToFixed(
                propsToCheckoutInfo.money
              )
            "
          />
          <label for="ShoppingGoldDiscount"
            >購物金折抵 (錢包餘額: {{ wallet }})</label
          >
        </div>
      </div>
      <!-- 備註 -->
      <div class="border rounded-3 pt-3">
        <p class="tw-border-start fw-bolder mb-3">備註</p>
        <div class="form-floating">
          <textarea
            class="form-control"
            placeholder="備註"
            id="Note"
            style="height: 150px"
            maxlength="300"
            v-model="propsToCheckoutInfo.note"
          ></textarea>
          <label for="Note">備註(300字)</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetCityArea } from '../../methods/API/getCityArea'

export default {
  props: {
    participantId: {
      type: [String, Number],
      default: 0,
    },
    propsToCheckoutInfo: {
      type: Object,
      default: function() {
        return {
          selectShppingType: '',
          selectPaymentType: '',
          name: '',
          phone: '',
          selectCity: '',
          selectArea: '',
          address: '',
          shippingCost: 0,
          D4shippingCost: 0,
          note: '',
          money: 0,
          wallet: 0,
          shopNumber: '',
          shopName: '',
        }
      }
    },
  },
  emits: ['fromCheckoutInfoData'],
  data() {
    return {
      serverToken: '',
      storeId: 0,
      currentPage: '',
      shippingTypes: {
        loading: false,
        data: []
      },
      paymentTypes: {
        loading: false,
        data: []
      },
      isClearArea: false,
      shippingTypeNote: '',
      paymentTypeNote: '',
      wallet: this.propsToCheckoutInfo.wallet,
      selectD4ReceivingInfo: null,
      receivingInfos: {
        loading: false,
        data: []
      },
    }
  },
  mixins: [GetCityArea],
  created() {
    this.currentPage = this.$route.name
    this.getStoreInfo()
    this.getCity()
  },
  watch: {
    // 父層傳進來的 participantId
    participantId: {
      handler(val) {
        // 從父層傳遞過來的 participantId 因為 immediate: true 的關係
        // 所以觸發的比這裡的 created 還早
        this.initialization()
        this.getParticipantInfo()
      },
      immediate: true
    },
    // 選縣市
    'propsToCheckoutInfo.selectCity'(val) {
      if (this.isClearArea) this.propsToCheckoutInfo.selectArea = ''
      this.isClearArea = false
      this.getArea(val)
    },
    // 選出貨方式
    'propsToCheckoutInfo.selectShppingType'(val) {
      // 取運費
      if (val) this.propsToCheckoutInfo.shippingCost = this.$methods.numberToFixed(val.split(',')[1])
      else this.propsToCheckoutInfo.shippingCost = 0
      // 取備註
      this.shippingTypeNote = ''
      this.shippingTypes.data.some(item => {
        const combineString = `${item.name},${item.cost}`
        if (val === combineString) {
          this.shippingTypeNote = item.tipMessage
          return true
        }
      })
    },
    // 若有預設運費就覆蓋過去
    'propsToCheckoutInfo.D4shippingCost' (val) {
      this.propsToCheckoutInfo.shippingCost = val
    },
    // 選付款方式
    'propsToCheckoutInfo.selectPaymentType'(val) {
      this.paymentTypeNote = ''
      this.paymentTypes.data.some(item => {
        if (val === item.name) {
          this.paymentTypeNote = item.note
          return true
        }
      })
    },
    // 電話格式
    'propsToCheckoutInfo.phone'(val) {
      if (val && !/^[0-9]*$/.test(val)) {
        this.propsToCheckoutInfo.phone = val.replace(/[^\d]/g, "");
      }
    },
    // 錢包餘額
    'propsToCheckoutInfo.wallet'(val) {
      this.wallet = val
    },
    // 購物金
    'propsToCheckoutInfo.money'(val) {
      if (val < 0) this.propsToCheckoutInfo.money = 0
      let money = val ? val : 0
      const wallet = this.propsToCheckoutInfo.wallet
      const finalWallet = this.$methods.numberToFixed(wallet - money)
      if (finalWallet < 0) this.propsToCheckoutInfo.money = wallet
      else this.wallet = finalWallet
    },
    // 選擇預設出貨資訊
    selectD4ReceivingInfo(val) {
      this.propsToCheckoutInfo.name = val.recipient
      this.propsToCheckoutInfo.selectCity = val.City
      this.propsToCheckoutInfo.selectArea = val.postalCode
      this.propsToCheckoutInfo.phone = val.phoneNumber
      this.propsToCheckoutInfo.address = val.address
    },
    // 若傳遞資料的物件有變動，就要馬上傳回父層
    propsToCheckoutInfo: {
      handler(val) {
        this.$emit('fromCheckoutInfoData', val)
      },
      deep: true
    }
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
    },
    // 清除 selectArea
    clearSelectArea() {
      this.isClearArea = true
    },
    // 取得顧客資訊
    getParticipantInfo() {
      const vm = this
      const getParticipantInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        type: 13,
        ids: [this.participantId],
        methods: '{getReceivingInfos{getPostalCodeDetail}}'
      }]
      this.receivingInfos = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getParticipantInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const participantInfo = res.data[0].objects[0]
            participantInfo.receivingInfos.forEach(item => {
              if(item.postalCodeDetail) {
                item.City = item.postalCodeDetail.City
                item.Area = item.postalCodeDetail.Area
                vm.receivingInfos.data.push(item)
                }
            });
            vm.receivingInfos.loading = false
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 取得賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getShippingTypes,getPaymentTypes}'
        }
      ]
      this.shippingTypes = {
        loading: true,
        data: []
      }
      this.paymentTypes = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.shippingTypes = {
              loading: false,
              data: storeInfo.shippingTypes
            }
            vm.paymentTypes = {
              loading: false,
              data: storeInfo.paymentTypes
            }
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
  },
}
</script>